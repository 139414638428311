import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedIn from "./routes/LoggedIn";
import NotLoggedIn from "./routes/NotLoggedIn";
import Login from "./page/Login";
import Home from "./page/Home";
import ErrorPage from "./page/Errorpage";
import { useMediaQuery } from "@mui/material";
import MobileErrorpage from "./page/MobileErrorpage";

function App() {
  const isTvScreen = useMediaQuery("(min-width: 1000px)");
  return (
    <div>
      <ToastContainer />{" "}
      {isTvScreen ? (
        <Routes>
          {/* <Route element={<LoggedIn />}> */}
          <Route path="/" exact element={<Home />} />
          {/* </Route> */}
          {/* <Route element={<NotLoggedIn />}>
            <Route path="/login" element={<Login />} />
          </Route>{" "} */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      ) : (
        <MobileErrorpage />
      )}
    </div>
  );
}

export default App;
